<template>
    <li class="top-members-data">
        <a :href="`https://www.paltalk.com/people/users/${user['nickname']}/index.wmt`" target="_blank">
            <div class="thumb">
                <img v-lazy="usrImg" :title="user['nickname']"/>
            </div>
            <div class="user-info">
                <div class="profile-info notranslate">
                    <h4 v-bind:style="nickColor">{{user['nickname']}}</h4>
                    <p>
                        <img :src="countryImg" :title="user['country']" v-show="hasCountry"/>
                        <span class="gender" v-show="gender !== ''">{{gender}}</span>
                        <span class="age" v-show="user['age'] > 0">{{age}}</span>
                    </p>
                </div>
                <div class="subscription-crown notranslate" v-show="user['subscription'] !== ''">
                    <span class="subscription" :class="[subs]">{{user['subscription']}}</span>
                </div>
            </div>
            <div class="overlay"><span class="view-profile">View Profile</span></div>
        </a>
    </li>
</template>

<script>
    import Vue from 'vue';
    import VueLazyLoad from 'vue-lazyload';

    Vue.use(VueLazyLoad, {
        preLoad: 1.3,
        error: 'https://people.palassets.com/noimage/noimage-160.jpg',
        loading: 'https://www.palassets.com/sprites/loader/loader-gray.gif',
        attempt: 1
    });

    export default {
        name: 'profile-card',
        props: {
            user: {
                type: Object,
                required: true
            },
            imgPrefix: {
                type: String,
                required: true,
                default: 'https://people.palassets.com/size160/'
            },
            countryImgPrefix: {
                type: Object,
                required: true,
                default: () => {
                    return {
                        url: 'https://www.palassets.com/countries/',
                        ext: 'gif'
                    }
                }
            },
        },
        data: () => ({}),
        computed: {
            usrImg() {
                if (this.user['primary_image'] === '') {
                    return 'https://people.palassets.com/noimage/noimage-160.jpg';
                }
                return `${this.imgPrefix}${this.user['primary_image']}`;
            },
            countryImg() {
                return `${this.countryImgPrefix.url}${this.user['country'].toLowerCase()}.${this.countryImgPrefix.ext}`;
            },
            hasCountry() {
                return this.user['country'] && this.user['country'].trim().length === 2;
            },
            gender() {
                const g = this.user['gender'];
                if (g !== '') {
                    return (g.toLowerCase() === 'f') ? 'Female' : 'Male';
                }
                return '';
            },
            age() {
                let html = '';
                if (this.user['gender'] !== '') {
                    html = `, ${this.user['age']}`;
                } else {
                    html = `${this.user['age']}`
                }
                return html;
            },
            subs() {
                return (this.user['subscription'].toLowerCase()) || '';
            },
            nickColor() {
                const color = this.user['nickColor'];
                if (color !== '') {
                    return {color: `rgb(${color.substr(0, 3)}, ${color.substr(3, 3)}, ${color.substr(6, 3)})`};
                }
                return {};
            }
        },
        methods: {},
    }
</script>

<style lang="scss" scoped>
    li {
        display: inline-block;
        width: 160px;
        background: #fff;
        a {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            width: 100%;
            .overlay {
                display: none;
            }
            &:hover {
                .overlay {
                    background: rgba(0, 163, 255, 0.70);
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    z-index: 98;
                    i {
                        position: relative;
                        top: 31.5px;
                    }
                    span {
                        position: relative;
                        top: 35px;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
                .user-info {
                    background: #00A2FF;
                    position: relative;
                    z-index: 99;
                    h4, p {
                        color: #fff;
                    }
                }
            }
        }
        .thumb {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            float: left;
            height: 114px;
            width: 100%;
            overflow: hidden;
            text-align: center;
            background-color: #cbe6f6;
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }
            img {
                width: 100%;
                height: auto;
                display: inline-block;
                vertical-align: middle;
                float: left;
                position: relative;
                margin: -3px 0;
                &[lazy="loading"] {
                    width: 35px;
                    height: auto;
                    margin: 0 auto;
                    float: none;
                }
                &.loader {
                    width: 35px;
                    height: 35px;
                    float: none;
                }
                &.no-img {
                    height: 100%;
                    width: auto;
                    float: none;
                    margin: 0;
                }
            }
        }
        .basic {
            background: #d5d5d5;
        }
        .plus {
            background: #2fa3de;
        }
        .extreme {
            background: #96cb25;
        }
        .vip {
            background: #983bc8;
        }
        .prime {
            background: #dcb400;
        }
        .user-info {
            padding: 10px;
            display: inline-block;
            vertical-align: middle;
            float: left;
            text-align: center;
            width: 137px;
            width: calc(100% - 20px);
            height: 73px;
            position: relative;
            .profile-info {
                /*height: 53px;*/
            }
            .crown {
                position: absolute;
                left: 68.5px;
                top: -11px;
                background: #f1f1f1;
                padding: 3px;
                border-radius: 100%;
                display: inline-block;
                width: 17px;
                height: 17px;
                img {
                    width: 17px;
                    height: auto;
                    margin: 0 auto;
                }
            }
            h4 {
                font-size: 17px;
                color: #494949;
                font-weight: 400;
                line-height: 1.33;
                margin-bottom: 8px;
                text-align: center;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                a {
                    color: #494949;
                }
            }
            p {
                font-size: 13px;
                color: #494949;
                font-weight: 300;
                margin-bottom: 7px;
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }
            .subscription {
                display: inline-block;
                vertical-align: middle;
                font-size: 11px;
                font-weight: 400;
                color: #fff;
                height: 12px;
                width: 69px;
                padding: 2px 0;
                text-transform: uppercase;
                text-align: center;
                position: relative;
                &.valign {
                    line-height: 11px;
                }
                &:after, &:before {
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    top: 50%;
                }
                &:after {
                    border-width: 8px 0 8px 4px;
                    margin-top: -8px;
                    left: 100%;
                }
                &:before {
                    border-width: 8px 4px 8px 0;
                    margin-top: -8px;
                    right: 100%;
                }
                &.extreme {
                    background: #96cb25;
                    &:after {
                        border-left-color: #96cb25;
                    }
                    &:before {
                        border-right-color: #96cb25;
                    }
                }
                &.plus, &.palplus {
                    background: #2FA3DE;
                    &:after {
                        border-left-color: #2FA3DE;
                    }
                    &:before {
                        border-right-color: #2FA3DE;
                    }
                }
                &.prime {
                    background: #dcb400;
                    &:after {
                        border-left-color: #dcb400;
                    }
                    &:before {
                        border-right-color: #dcb400;
                    }
                }
                &.vip {
                    background: #983BC8;
                    &:after {
                        border-left-color: #983BC8;
                    }
                    &:before {
                        border-right-color: #983BC8;
                    }
                }
            }
            @media all and (-moz-images-in-menus: 0) {
                @media (min-width: 0px) {
                    .subscription {
                        line-height: 12px;
                        height: auto;
                        &.valign {
                            line-height: 12px;
                        }
                    }
                }
            }
            @media screen and (min-width: 0 \0
            ) {
                .subscription {
                    line-height: 1;
                    vertical-align: sub;
                    position: relative;
                }
            }
        }
    }
</style>
