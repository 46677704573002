import Vue from 'vue';
import Router from 'vue-router';
import SearchPeople from './SearchPeople';


Vue.use(Router);

const router = new Router({
    mode: 'hash',
    routes: [
        { path: '/', redirect: '/people' },
        { path: '/people', name: 'searchPeople', component: SearchPeople },
        { path: '/groups', name: 'searchGroups', component: () => import(/* webpackChunkName: "groups" */ './SearchGroups') },
        { path: '/groups/categories/:seoCategory', name: 'searchCategories', component: () => import(/* webpackChunkName: "categories" */ './SearchCategories') },
        { path: '/groups/categories/:seoCategory/:seoSubCategory', name: 'searchSubCategories', component: () => import(/* webpackChunkName: "subcategories" */ './SearchSubCategories') },
        { path: '*', redirect: '/people' }
    ]
});

router.beforeEach((to, from, next) => {
    next();
});

export default router;
