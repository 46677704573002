const countries = [
    {
        label: 'WorldWide',
        value: ''
    },
    {
        label: 'Afghanistan',
        value: 'AF'
    },
    {
        label: 'Albania',
        value: 'AL'
    },
    {
        label: 'Algeria',
        value: 'DZ'
    },
    {
        label: 'American Samoa',
        value: 'AS'
    },
    {
        label: 'Andorra',
        value: 'AD'
    },
    {
        label: 'Angola',
        value: 'AO'
    },
    {
        label: 'Anguilla',
        value: 'Av'
    },
    {
        label: 'Antarctica',
        value: 'AQ'
    },
    {
        label: 'Antigua & Barbuda',
        value: 'AG'
    },
    {
        label: 'Argentina',
        value: 'AR'
    },
    {
        label: 'Armenia',
        value: 'AM'
    },
    {
        label: 'Aruba',
        value: 'AA'
    },
    {
        label: 'Australia',
        value: 'AU'
    },
    {
        label: 'Austria',
        value: 'AT'
    },
    {
        label: 'Azerbaijan',
        value: 'AZ'
    },
    {
        label: 'Bahamas',
        value: 'BS'
    },
    {
        label: 'Bahrain',
        value: 'BH'
    },
    {
        label: 'Bangladesh',
        value: 'BD'
    },
    {
        label: 'Barbados',
        value: 'BB'
    },
    {
        label: 'Belarus',
        value: 'BY'
    },
    {
        label: 'Belgium',
        value: 'BE'
    },
    {
        label: 'Belize',
        value: 'BZ'
    },
    {
        label: 'Benin',
        value: 'BJ'
    },
    {
        label: 'Bermuda',
        value: 'BM'
    },
    {
        label: 'Bhutan',
        value: 'BT'
    },
    {
        label: 'Bolivia',
        value: 'BO'
    },
    {
        label: 'Bosnia',
        value: 'BA'
    },
    {
        label: 'Botswana',
        value: 'BW'
    },
    {
        label: 'Bouvet Island',
        value: 'BV'
    },
    {
        label: 'Br. Indian Ocean',
        value: 'IO'
    },
    {
        label: 'Brazil',
        value: 'BR'
    },
    {
        label: 'Brunei Darussalam',
        value: 'BN'
    },
    {
        label: 'Bulgaria',
        value: 'BG'
    },
    {
        label: 'Burkina Faso',
        value: 'BF'
    },
    {
        label: 'Burundi',
        value: 'BI'
    },
    {
        label: 'Cambodia',
        value: 'KH'
    },
    {
        label: 'Cameroon',
        value: 'CM'
    },
    {
        label: 'Canada',
        value: 'CA'
    },
    {
        label: 'Cape Verde',
        value: 'CV'
    },
    {
        label: 'Cayman Islands',
        value: 'KY'
    },
    {
        label: 'Central Africa',
        value: 'CF'
    },
    {
        label: 'Chad',
        value: 'TD'
    },
    {
        label: 'Chile',
        value: 'CL'
    },
    {
        label: 'China',
        value: 'CN'
    },
    {
        label: 'Christmas Island',
        value: 'CX'
    },
    {
        label: 'Cocos Islands',
        value: 'CC'
    },
    {
        label: 'Colombia',
        value: 'CO'
    },
    {
        label: 'Comoros',
        value: 'KM'
    },
    {
        label: 'Congo',
        value: 'CG'
    },
    {
        label: 'Congo, Dem Rep',
        value: 'CD'
    },
    {
        label: 'Cook Islands',
        value: 'CK'
    },
    {
        label: 'Costa Rica',
        value: 'CR'
    },
    {
        label: 'Croatia',
        value: 'HR'
    },
    {
        label: 'Cuba',
        value: 'CU'
    },
    {
        label: 'Cyprus',
        value: 'CY'
    },
    {
        label: 'Czech (former)',
        value: 'CS'
    },
    {
        label: 'Czech Republic',
        value: 'CZ'
    },
    {
        label: 'Denmark',
        value: 'DK'
    },
    {
        label: 'Djibouti',
        value: 'DJ'
    },
    {
        label: 'Dominica',
        value: 'DM'
    },
    {
        label: 'Dominican Republic',
        value: 'DO'
    },
    {
        label: 'East Timor',
        value: 'TP'
    },
    {
        label: 'Ecuador',
        value: 'EC'
    },
    {
        label: 'Egypt',
        value: 'EG'
    },
    {
        label: 'El Salvador',
        value: 'SV'
    },
    {
        label: 'Equatorial Guinea',
        value: 'GQ'
    },
    {
        label: 'Eritrea',
        value: 'ER'
    },
    {
        label: 'Estonia',
        value: 'EE'
    },
    {
        label: 'Ethiopia',
        value: 'ET'
    },
    {
        label: 'F.Y.R.O.M',
        value: 'MK'
    },
    {
        label: 'Falkland Islands',
        value: 'FK'
    },
    {
        label: 'Faroe Islands',
        value: 'FO'
    },
    {
        label: 'Fiji',
        value: 'FJ'
    },
    {
        label: 'Finland',
        value: 'FI'
    },
    {
        label: 'France',
        value: 'FR'
    },
    {
        label: 'France, Metro',
        value: 'FX'
    },
    {
        label: 'French Guiana',
        value: 'GF'
    },
    {
        label: 'French Polynesia',
        value: 'PF'
    },
    {
        label: 'French So. Terr',
        value: 'TF'
    },
    {
        label: 'Gabon',
        value: 'GA'
    },
    {
        label: 'Gambia',
        value: 'GM'
    },
    {
        label: 'Georgia',
        value: 'GE'
    },
    {
        label: 'Germany',
        value: 'DE'
    },
    {
        label: 'Ghana',
        value: 'GH'
    },
    {
        label: 'Gibraltar',
        value: 'GI'
    },
    {
        label: 'Great Britain (UK)',
        value: 'GB'
    },
    {
        label: 'Greece',
        value: 'GR'
    },
    {
        label: 'Greenland',
        value: 'GL'
    },
    {
        label: 'Grenada',
        value: 'GD'
    },
    {
        label: 'Guadeloupe',
        value: 'GP'
    },
    {
        label: 'Guam',
        value: 'GU'
    },
    {
        label: 'Guatemala',
        value: 'GT'
    },
    {
        label: 'Guinea',
        value: 'GN'
    },
    {
        label: 'Guinea-Bissau',
        value: 'GW'
    },
    {
        label: 'Guyana',
        value: 'GY'
    },
    {
        label: 'Haiti',
        value: 'HT'
    },
    {
        label: 'Heard & McDon Isls',
        value: 'HM'
    },
    {
        label: 'Honduras',
        value: 'HN'
    },
    {
        label: 'Hong Kong',
        value: 'HK'
    },
    {
        label: 'Hungary',
        value: 'HU'
    },
    {
        label: 'Iceland',
        value: 'IS'
    },
    {
        label: 'India',
        value: 'IN'
    },
    {
        label: 'Indonesia',
        value: 'ID'
    },
    {
        label: 'Iran',
        value: 'IR'
    },
    {
        label: 'Iraq',
        value: 'IQ'
    },
    {
        label: 'Ireland',
        value: 'IE'
    },
    {
        label: 'Israel',
        value: 'IL'
    },
    {
        label: 'Italy',
        value: 'IT'
    },
    {
        label: 'Ivory Coast',
        value: 'CI'
    },
    {
        label: 'Jamaica',
        value: 'JM'
    },
    {
        label: 'Japan',
        value: 'JP'
    },
    {
        label: 'Jordan',
        value: 'JO'
    },
    {
        label: 'Kazakhstan',
        value: 'KZ'
    },
    {
        label: 'Kenya',
        value: 'KE'
    },
    {
        label: 'Kiribati',
        value: 'KI'
    },
    {
        label: 'Korea (North)',
        value: 'KP'
    },
    {
        label: 'Korea (South)',
        value: 'KR'
    },
    {
        label: 'Kuwait',
        value: 'KW'
    },
    {
        label: 'Kyrgyzstan',
        value: 'KG'
    },
    {
        label: 'Laos',
        value: 'LA'
    },
    {
        label: 'Latvia',
        value: 'LV'
    },
    {
        label: 'Lebanon',
        value: 'LB'
    },
    {
        label: 'Lesotho',
        value: 'LS'
    },
    {
        label: 'Liberia',
        value: 'LR'
    },
    {
        label: 'Libya',
        value: 'LY'
    },
    {
        label: 'Liechtenstein',
        value: 'LI'
    },
    {
        label: 'Lithuania',
        value: 'LT'
    },
    {
        label: 'Luxembourg',
        value: 'LU'
    },
    {
        label: 'Macau',
        value: 'MO'
    },
    {
        label: 'Madagascar',
        value: 'MG'
    },
    {
        label: 'Malawi',
        value: 'MW'
    },
    {
        label: 'Malaysia',
        value: 'MY'
    },
    {
        label: 'Maldives',
        value: 'MV'
    },
    {
        label: 'Mali',
        value: 'ML'
    },
    {
        label: 'Malta',
        value: 'MT'
    },
    {
        label: 'Marshall Islands',
        value: 'MH'
    },
    {
        label: 'Martinique',
        value: 'MQ'
    },
    {
        label: 'Mauritania',
        value: 'MR'
    },
    {
        label: 'Mauritius',
        value: 'MU'
    },
    {
        label: 'Mayotte',
        value: 'YT'
    },
    {
        label: 'Mexico',
        value: 'MX'
    },
    {
        label: 'Micronesia',
        value: 'FM'
    },
    {
        label: 'Moldova',
        value: 'MD'
    },
    {
        label: 'Monaco',
        value: 'MC'
    },
    {
        label: 'Mongolia',
        value: 'MN'
    },
    {
        label: 'Montserrat',
        value: 'MS'
    },
    {
        label: 'Morocco',
        value: 'MA'
    },
    {
        label: 'Mozambique',
        value: 'MZ'
    },
    {
        label: 'Myanmar',
        value: 'MM'
    },
    {
        label: 'Namibia',
        value: 'NA'
    },
    {
        label: 'Nauru',
        value: 'NR'
    },
    {
        label: 'Nepal',
        value: 'NP'
    },
    {
        label: 'Netherland Antilles',
        value: 'AN'
    },
    {
        label: 'Netherlands',
        value: 'NL'
    },
    {
        label: 'Neutral Zone',
        value: 'NT'
    },
    {
        label: 'New Caledonia',
        value: 'NC'
    },
    {
        label: 'New Zealand',
        value: 'NZ'
    },
    {
        label: 'Nicaragua',
        value: 'NI'
    },
    {
        label: 'Niger',
        value: 'NE'
    },
    {
        label: 'Nigeria',
        value: 'NG'
    },
    {
        label: 'Niue',
        value: 'NU'
    },
    {
        label: 'No. Mariana Isles',
        value: 'MP'
    },
    {
        label: 'Norfolk Island',
        value: 'NF'
    },
    {
        label: 'Norway',
        value: 'NO'
    },
    {
        label: 'Oman',
        value: 'OM'
    },
    {
        label: 'Outside US',
        value: 'OU'
    },
    {
        label: 'Pakistan',
        value: 'PK'
    },
    {
        label: 'Palau',
        value: 'PW'
    },
    {
        label: 'Panama',
        value: 'PA'
    },
    {
        label: 'Papua New Guinea',
        value: 'PG'
    },
    {
        label: 'Paraguay',
        value: 'PY'
    },
    {
        label: 'Peru',
        value: 'PE'
    },
    {
        label: 'Philippines',
        value: 'PH'
    },
    {
        label: 'Pitcairn',
        value: 'PN'
    },
    {
        label: 'Poland',
        value: 'PL'
    },
    {
        label: 'Portugal',
        value: 'PT'
    },
    {
        label: 'Puerto Rico',
        value: 'PR'
    },
    {
        label: 'Qatar',
        value: 'QA'
    },
    {
        label: 'Reunion',
        value: 'RE'
    },
    {
        label: 'Romania',
        value: 'RO'
    },
    {
        label: 'Russian Federation',
        value: 'RU'
    },
    {
        label: 'Rwanda',
        value: 'RW'
    },
    {
        label: 'S.Georgia',
        value: 'GS'
    },
    {
        label: 'Saint Kitts & Nevis',
        value: 'KN'
    },
    {
        label: 'Saint Lucia',
        value: 'LC'
    },
    {
        label: 'Samoa',
        value: 'WS'
    },
    {
        label: 'San Marino',
        value: 'SM'
    },
    {
        label: 'Sao Tome & Principe',
        value: 'ST'
    },
    {
        label: 'Saudi Arabia',
        value: 'SA'
    },
    {
        label: 'Senegal',
        value: 'SN'
    },
    {
        label: 'Seychelles',
        value: 'SC'
    },
    {
        label: 'Sierra Leone',
        value: 'SL'
    },
    {
        label: 'Singapore',
        value: 'SG'
    },
    {
        label: 'Slovak Republic',
        value: 'SK'
    },
    {
        label: 'Slovenia',
        value: 'SI'
    },
    {
        label: 'Solomon Islands',
        value: 'Sb'
    },
    {
        label: 'Somalia',
        value: 'SO'
    },
    {
        label: 'South Africa',
        value: 'ZA'
    },
    {
        label: 'Spain',
        value: 'ES'
    },
    {
        label: 'Sri Lanka',
        value: 'LK'
    },
    {
        label: 'St. Helena',
        value: 'SH'
    },
    {
        label: 'St.Pierre',
        value: 'PM'
    },
    {
        label: 'St.Vincent',
        value: 'VC'
    },
    {
        label: 'Sudan',
        value: 'SD'
    },
    {
        label: 'Suriname',
        value: 'SR'
    },
    {
        label: 'Svalbard',
        value: 'SJ'
    },
    {
        label: 'Swaziland',
        value: 'SZ'
    },
    {
        label: 'Sweden',
        value: 'SE'
    },
    {
        label: 'Switzerland',
        value: 'CH'
    },
    {
        label: 'Syria',
        value: 'SY'
    },
    {
        label: 'Taiwan',
        value: 'TW'
    },
    {
        label: 'Tajikistan',
        value: 'TJ'
    },
    {
        label: 'Tanzania',
        value: 'TZ'
    },
    {
        label: 'Thailand',
        value: 'TH'
    },
    {
        label: 'Togo',
        value: 'TG'
    },
    {
        label: 'Tokelau',
        value: 'TK'
    },
    {
        label: 'Tonga',
        value: 'TO'
    },
    {
        label: 'Trinidad & Tobago',
        value: 'TT'
    },
    {
        label: 'Tunisia',
        value: 'TN'
    },
    {
        label: 'Turkey',
        value: 'TR'
    },
    {
        label: 'Turkmenistan',
        value: 'TM'
    },
    {
        label: 'Turks & Caicos Isles',
        value: 'TC'
    },
    {
        label: 'Tuvalu',
        value: 'TV'
    },
    {
        label: 'UAE',
        value: 'AE'
    },
    {
        label: 'Uganda',
        value: 'UG'
    },
    {
        label: 'Ukraine',
        value: 'UA'
    },
    {
        label: 'United States',
        value: 'US'
    },
    {
        label: 'Uruguay',
        value: 'UY'
    },
    {
        label: 'US Minor Isles',
        value: 'UM'
    },
    {
        label: 'USSR (former)',
        value: 'SU'
    },
    {
        label: 'Uzbekistan',
        value: 'UZ'
    },
    {
        label: 'Vanuatu',
        value: 'VU'
    },
    {
        label: 'Vatican City State',
        value: 'VA'
    },
    {
        label: 'Venezuela',
        value: 'VE'
    },
    {
        label: 'Viet Nam',
        value: 'VN'
    },
    {
        label: 'Virgin Isles (British)',
        value: 'VG'
    },
    {
        label: 'Virgin Isles (U.S.)',
        value: 'VI'
    },
    {
        label: 'Wallis & Futuna Isls',
        value: 'WF'
    },
    {
        label: 'Western Sahara',
        value: 'EH'
    },
    {
        label: 'WorldWide',
        value: 'WW'
    },
    {
        label: 'Yemen',
        value: 'YE'
    },
    {
        label: 'Yugoslavia',
        value: 'YU'
    },
    {
        label: 'Zaire',
        value: 'ZR'
    },
    {
        label: 'Zambia',
        value: 'ZM'
    },
    {
        label: 'Zimbabwe',
        value: 'ZW'
    }
];

export default countries;