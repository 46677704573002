const languages = [
    {
        code: 'ar',
        label: 'العربية'
    },
    {
        code: 'en',
        label: 'English'
    },
    {
        code: 'fr',
        label: 'Français'
    },
    {
        code: 'de',
        label: 'Deutsch'
    },
    {
        code: 'it',
        label: 'Italiano'
    },
    {
        code: 'pt-br',
        label: 'Português'
    },
    {
        code: 'es',
        label: 'Español'
    },
    {
        code: 'sv',
        label: 'Svenska'
    },
    {
        code: 'vi',
        label: 'Tiếng Việt'
    }
];

export default languages;
