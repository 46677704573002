import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.paltalkconnect.com/api',
    timeout: 30000,
    withCredentials: false,
});

export default {
    getOnlineStats() {
        return api.get('/onlineStats');
    },
}