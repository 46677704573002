<template>
    <div class="ad-container">
        <div class="ad-160x600">
            <p>advertisement</p>
          <div class="vlmbrm" id="left300" data-aa-pos="left"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'left-ad'
    }
</script>

<style lang="scss" scoped>
    .ad-container {
        display: block;
        width: 100%;
        text-align: center;

        .ad-160x600 {
            display: inline-flex;
            justify-content: center;
            width: 100%;
            height: auto;
            float: none;
            padding: 20px 0 17px;
            position: relative;
            background: #fff;

            p {
                font-size: 10px;
                text-align: right;
                color: rgba(0, 0, 0, 0.4);
                text-transform: uppercase;
                font-weight: 300;
                position: absolute;
                right: 19px;
                top: 5px;
            }

        }
    }

    @media all and (max-width: 767px) {
        .ad-container {
            display: inline-block;
            clear: both;
        }
    }

    @media all and (max-width: 360px) {
        .ad-container {
            width: 100%;
            .ad-160x600 {
                background: transparent;
                padding-bottom: 0;
            }
        }
    }

    @media all and (max-width: 340px) {
        .ad-container {
            width: 100vw !important;
            margin-left: -10px;
            margin-right: 0;
            text-align: center;
        }
    }
</style>