import axios from 'axios';

const api = axios.create({
    baseURL: 'https://livesearch-api.paltalkconnect.com/paltalk',
    timeout: 30000,
    withCredentials: false,
});

const CancelToken = axios.CancelToken;
let sourceArr = {};

function cancelableGet(...args) {
    const source = CancelToken.source();
    const promise = api.get(...args, {
        cancelToken: source.token
    });
    return {
        promise, source
    }
}

export default {
    getAllCategories() {
        const urlPath = '/codes/categories';
        const source = sourceArr[urlPath];
        if (source) {
            source.cancel();
        }
        const request = cancelableGet(urlPath);
        sourceArr[urlPath] = request.source;
        return request.promise;
    },
    getAllSubCategories(categoryCode) {
        const urlPath = '/codes/subcategories';
        const source = sourceArr[urlPath];
        if (source) {
            source.cancel();
        }
        const params = `category=${categoryCode}`;
        const request = cancelableGet(`${urlPath}?${params}`);
        sourceArr[urlPath] = request.source;
        return request.promise;
    },
    getAllLanguages(){
        const urlPath = '/codes/languages';
        const source = sourceArr[urlPath];
        if (source) {
            source.cancel();
        }
        const request = cancelableGet(urlPath);
        sourceArr[urlPath] = request.source;
        return request.promise;
    },
    searchAllUsers(filters) {
        const urlPath = '/users/all';
        const source = sourceArr[urlPath];
        let params = '';
        Object.keys(filters).forEach((key) => {
            params += `&${key}=${filters[key]}`;
        });
        params = params.substring(1);
        if (source) {
            source.cancel();
        }
        const request = cancelableGet(`${urlPath}?${params}`);
        sourceArr[urlPath] = request.source;
        return request.promise;
    },
    searchAllGroups(filters) {
        const urlPath = '/search/groups';
        const source = sourceArr[urlPath];
        let params = '';
        Object.keys(filters).forEach((key) => {
            params += `&${key}=${filters[key]}`;
        });
        params = params.substring(1);
        if (source) {
            source.cancel();
        }
        const request = cancelableGet(`${urlPath}?${params}`);
        sourceArr[urlPath] = request.source;
        return request.promise;
    },
}
