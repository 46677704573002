<template>
    <header>
        <div id="header">
            <div class="section clearfix">
                <a href="https://www.paltalk.com" title="Home" rel="home" id="pt-logo"></a>
                <a href="javascript:void(0)" class="menu-link"></a>
                <nav id="nav-menu" role="navigation">
                    <h2>Main menu</h2>
                    <ul id="main-menu" class="links inline clearfix">
                        <li class="menu-559" v-show="!isLoggedIn"><a href="https://www.paltalk.com/features">Features</a></li>
                        <li class="menu-668" :class="{ 'active': active === 'people' }"><a href="https://www.paltalk.com/people/webapp/index.wmt">Members</a></li>
                        <li class="menu-667" :class="{ 'active': active === 'groups' }"><a href="https://www.paltalk.com/g2/webapp/groups/GroupsPage.wmt">Chat Rooms</a></li>
                        <li class="menu-560">
                            <a href="https://www.paltalk.com/products">Products<span class="caret" v-show="isLoggedIn"></span></a>
                            <ul class="dropdown-menu" role="menu" v-show="isLoggedIn">
                                <li><a href="https://www.paltalk.com/Video-Chatting-Features">Paltalk</a></li>
                                <li><a href="https://www.paltalk.com/mobile/">Mobile</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h2>Secondary menu</h2>
                    <ul id="secondary-menu" class="links inline myaccount clearfix">
                        <li class="menu-606 my-account" v-show="isLoggedIn">
                            <a href="https://commerce.paltalk.com/mpt/MyPalTalkHome.jsp" id="my-account-link-1">My Account</a>
                        </li>
                        <li class="menu-607" v-show="isLoggedIn">
                            <form action="https://commerce.paltalk.com/sson/login" method="post" id="formlogout" name="logout">
                                <input type="hidden" name="action" value="logout"/>
                                <input type="hidden" name="target" value="https://browse.paltalk.com/#/people"/>
                                <input type="submit" class="sign-in" value="Sign Out"/>
                            </form>
                        </li>
                        <li class="menu-608"><a href="javascript:void(0);" class="sign-in" @click="showLoginPage()" v-show="!isLoggedIn">Sign In</a></li>
                        <li class="menu-609" id="downloadpaltalk" v-show="!isLoggedIn">
                            <a href="#" class="red-button header-cta" @click="directdownload">Download Paltalk</a>
                        </li>
                    </ul>
                </nav>
                <div class="profile-info" v-show="isLoggedIn">
                    <a href="https://commerce.paltalk.com/mpt/MyPalTalkHome.jsp" id="my-account-link-2" class="myacct">
                        <img v-lazy="userProfileImg" :alt="username"/>
                        <span class="nickname notranslate">{{username}}<span class="caret"></span></span>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <li><a href="https://commerce.paltalk.com/mpt/MyPalTalkHome.jsp">My Account</a></li>
                        <li><a :href="`https://www.paltalk.com/people/users/${username}/toolbox/index.wmt`" id="manageprofile">Manage My Profile</a></li>
                        <li><a href="https://www.paltalk.com/people/webapp/my/info.wmt?view=profile">My Profile</a></li>
                        <li><a href="https://commerce.paltalk.com/mpt/ControllerServlet?RequestId=MyPalTalk.Photos">My Photos</a></li>
                        <li><a :href="`https://www.paltalk.com/people/users/${username}/MyRewards.wmt`">My Rewards</a></li>
                        <li><a href="https://commerce.paltalk.com/mpt/ControllerServlet?RequestId=MyPalTalk.MyRoomSettings" id="manageroom">Manage My Room</a></li>
                        <li><a href="https://www.paltalk.com/g2/webapp/my/view_group.wmt">Room Profile</a></li>
                        <li><a href="https://commerce.paltalk.com/mpt/ControllerServlet?RequestId=MyPalTalk.RoomsIManage">Rooms I Manage</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <iframe id="download_iframe" style="display:none;"></iframe>
    </header>
</template>

<script>
    import Vue from 'vue';
    import VueCookies from 'vue-cookies';
    import VueLazyLoad from 'vue-lazyload';

    Vue.use(VueCookies);
    Vue.use(VueLazyLoad, {
        preLoad: 1.3,
        error: 'https://people.palassets.com/noimage/noimage-35.jpg',
        loading: 'https://www.palassets.com/sprites/loader/loader-gray.gif',
        attempt: 1
    });

    export default {
        name: 'app-header',
        data: () => ({
            isLoggedIn: false,
            username: '',
            userProfileImg: '',
            active: ''
        }),
        methods: {
            showLoginPage() {
                window.location.href = `https://commerce.paltalk.com/mpt/ControllerServlet?RequestId=MyPalTalk.PreLogin&continue=${encodeURIComponent(window.location.href)}`;
            },
            setActive() {
                if (this.$route.path.indexOf('/people') !== -1) {
                    this.active = 'people';
                } else {
                    this.active = 'groups';
                }
            },
            directdownload() {
                  var platform = navigator.platform.toUpperCase(),
                  agentName = navigator.userAgent.toUpperCase();
                    if (platform.indexOf('MAC') !== -1) {
                        var downloadExeMac = "https://downloads.paltalk.com/download/osx/paltalk.dmg";
                        document.getElementById('download_iframe').setAttribute('src', downloadExeMac);
                    } 
                    else if (agentName.indexOf("WIN") != -1) {
                        var downloadExeWin = "https://downloads.paltalk.com/download/win/PaltalkSetup.exe";
                        document.getElementById('download_iframe').setAttribute('src', downloadExeWin);
                    }
                    setTimeout(function () {
                        var finalUrl = 'https://register.paltalk.com/reg/ControllerServlet?RequestId=Register.ThankYou&src=browseapp'; 
                        window.location.href = finalUrl;
                    }, 2000);
            },
        },
        mounted() {
            const ssoCode = (this.$cookies.get('SsoCode')) || 0;
            if (parseInt(ssoCode, 10) === 1) {
                this.isLoggedIn = true;
                this.username = this.$cookies.get('username');
                this.userProfileImg = (this.$cookies.get('userProfileImg')) || 'https://people.palassets.com/noimage/noimage-35.jpg';
            }
            this.setActive();
        },
        watch: {
            '$route': function () {
                this.setActive();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .caret {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        border-top: 7px dashed;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        color: #c7c7c7;
        &:hover {
            color: #5c7483;
        }
    }

    header {
        margin: 0 auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        #header {
            background: #fff;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
            height: 56px;
            max-height: 56px;
            padding: 0;
            position: relative;
            div, a, li, section {
                box-sizing: border-box;
            }
            .section {
                padding: 0 32px;
                width: 100%;
                margin: 0 auto;
                max-width: 1312px;
                h2 {
                    display: none;
                }
                li {
                    a {
                        -webkit-font-smoothing: antialiased !important;
                    }
                }
                #pt-logo {
                    margin: 11px 33px 0 0;
                    float: left;
                    display: inline-block;
                    vertical-align: middle;
                    background: transparent url("https://www.palassets.com/web/responsive/images/paltalk_logo.svg") no-repeat 0 0;
                    background-size: contain;
                    height: 32px;
                    width: 118px;
                }
                .profile-info {
                    display: inline-block;
                    vertical-align: middle;
                    float: right;
                    margin-right: 0;
                    margin-top: 11px;
                    position: relative;
                    a {
                        color: #5c7483;
                        font-weight: 400;
                        font-size: 16px;
                        font-size: 1.6rem;
                        line-height: 30px;
                    }
                    img {
                        display: inline-block;
                        vertical-align: middle;
                        width: 35px;
                        height: 35px;
                        border-radius: 35px;
                        margin-right: 9px;
                    }
                    a:hover, img:hover {
                        text-decoration: none;
                        .nickname {
                            color: #f76652;
                            text-decoration: underline;
                        }
                    }
                    .nickname {
                        .caret {
                            display: none;
                            margin-left: 5px;
                        }
                    }
                    &:hover {
                        color: #f76652;
                        a {
                            color: #f76652;
                            .caret {
                                color: #5c7483;
                            }
                            #pt-nickname {
                                &::after {
                                    color: #5c7483;
                                }
                            }
                        }
                    }
                    ul.dropdown-menu {
                        display: none;
                        position: absolute;
                        top: 25px;
                        right: 0;
                        margin-right: -65px;
                        padding-top: 20px;
                        box-shadow: 0 1px -1px rgba(0, 0, 0, 10);
                        li {
                            display: block;
                            clear: left;
                            float: left;
                            width: 100%;
                            min-width: 180px;
                            background: #f6f5f2;
                            white-space: nowrap;
                            border-left: solid 1px #eee;
                            border-right: solid 1px #eee;
                            border-top: solid 1px #eee;
                            a {
                                color: #5c7483;
                                padding: 5px 15px;
                                margin: 0;
                                display: block;
                                float: none;
                                &:hover {
                                    color: #f76652;
                                    background: #eee;
                                    text-decoration: none;
                                }
                            }
                            &:first-child::before {
                                @extend .caret;
                                position: absolute;
                                left: 50%;
                                top: 20px;
                                margin-left: -4px;
                                color: #fff;
                                border-left-width: 7px;
                                border-right-width: 7px;
                            }
                            &:first-child:hover::before {
                                color: #fff;
                            }
                        }
                    }
                }
                .form-item {
                    margin: 0;
                }
                ul.inline li {
                    padding: 0;
                }
                #main-menu {
                    float: left;
                    margin: 2px 0 0;
                    li {
                        position: relative;
                        display: inline-block;
                        &.features {
                            display: none;
                        }
                        a {
                            color: #5c7483;
                            font-weight: 400;
                            font-size: 16px;
                            font-size: 1.6rem;
                            float: left;
                            line-height: 26px;
                            margin: 14px 24px 0 0;
                            .caret {
                                margin-left: 4px;
                                display: none;
                            }
                            &:hover {
                                color: #f76652;
                                text-decoration: underline;
                                .caret {
                                    color: #5c7483;
                                    text-decoration: none;
                                }
                            }
                        }
                        &.active a {
                            opacity: 1;
                            color: #1895d2;
                        }
                        &:first-child a {
                            margin-left: 0;
                        }
                        ul.dropdown-menu {
                            display: none;
                            position: absolute;
                            top: 36px;
                            left: 50%;
                            margin-left: -90px;
                            padding-top: 20px;
                            border-bottom: solid 1px #eee;
                            box-shadow: 0 1px -1px rgba(0, 0, 0, 10);
                            li {
                                display: block;
                                clear: left;
                                float: left;
                                width: 100%;
                                min-width: 180px;
                                background: #f6f5f2;
                                white-space: nowrap;
                                border-left: solid 1px #eee;
                                border-right: solid 1px #eee;
                                border-top: solid 1px #eee;
                                a {
                                    color: #5c7483;
                                    padding: 5px 15px;
                                    margin: 0;
                                    display: block;
                                    float: none;
                                    &:hover {
                                        color: #f76652;
                                        background: #eee;
                                        text-decoration: none;
                                    }
                                }
                                &:first-child::before {
                                    @extend .caret;
                                    position: absolute;
                                    left: 40%;
                                    top: -1px;
                                    color: #fff;
                                    border-left-width: 7px;
                                    border-right-width: 7px;
                                }
                                &:first-child:hover::before {
                                    color: #fff;
                                }
                            }
                        }
                        &:hover {
                            color: #f76652;
                            a {
                                color: #f76652;
                                .caret {
                                    color: #5c7483;
                                }
                            }
                        }
                    }
                }
                #secondary-menu {
                    float: right;
                    margin: 10px 0 0 !important;
                    min-height: 32px;
                    li {
                        padding: 0;
                        text-align: center;
                        color: #5c7483;
                        font-weight: 400;
                        font-size: 16px;
                        font-size: 1.6rem;
                        line-height: 30px;
                        &:first-child {
                            margin: 0;
                        }
                        &.my-account {
                            display: none;
                            &:hover a {
                                color: #f76652;
                            }
                        }
                        a {
                            margin: 0 0 0 12px;
                        }

                    }
                    .red-button {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 19px;
                        color: #fff;
                        text-align: center;
                        margin-left: 15px;
                        padding: 8px 23px;
                        background: #FF745F;
                        border-radius: 19px;
                        border: solid 1px #FF745F;
                        text-decoration: none;
                        cursor: pointer;
                        &:hover {
                            background: #FF8774;
                            border-color: #FF8774;
                        }
                        &:active {
                            background: #F26550;
                            border-color: #F26550;
                        }
                    }
                    .sign-in {
                        display: inline-block;
                        vertical-align: middle;
                        font-family: "Roboto", Helvetica, Arial, sans-serif;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 19px;
                        color: #59baff;
                        text-align: center;
                        padding: 8px 23px;
                        background: transparent;
                        border-radius: 19px;
                        border: solid 1px #98d5f1;
                        text-decoration: none;
                        cursor: pointer;
                        &:hover {
                            background: #71c5ff;
                            border-color: #71c5ff;
                            color: #fff;
                        }
                        &:active {
                            background: #51b0f2;
                            border-color: #51b0f2;
                            color: #fff;
                        }
                    }
                    &.myaccount {
                        vertical-align: top;
                        li {
                            vertical-align: top;
                        }
                    }
                    .menu-607 {
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media all and(min-width: 1300px) {
        header {
            #header {
                .section {
                    #pt-logo {
                        margin: 11px 33px 0 0;
                    }
                    #secondary-menu {
                        margin: 16px 0 0;
                    }
                }
            }
        }
    }

    @media all and(min-width: 1025px) {
        header {
            #header {
                .section {
                    #main-menu {
                        li {
                            a {
                                .caret {
                                    display: inline-block;
                                }
                            }
                            &:hover {
                                ul.dropdown-menu {
                                    display: block;
                                }
                            }
                        }
                    }
                    .profile-info {
                        .nickname {
                            .caret {
                                display: inline-block;
                            }
                            &#pt-nickname {
                                &::after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    display: inline-block;
                                    vertical-align: middle;
                                    border-top: 7px dashed;
                                    border-right: 5px solid transparent;
                                    border-left: 5px solid transparent;
                                    color: #c7c7c7;
                                    margin-left: 5px;
                                    &:hover {
                                        color: #5c7483;
                                    }
                                }
                            }
                        }
                        &:hover {
                            ul.dropdown-menu {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 801px) {
        header {
            #header {
                .section {
                    #nav-menu {
                        ul {
                            margin: 0 0 0 -0.25em;
                        }
                        li {
                            display: inline-block;
                            a {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        #nav-menu {
            max-height: none;
        }
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            header {
                #header {
                    .section {
                        #nav-menu {
                            ul {
                                margin-top: -0.15em;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width: 950px) and (max-width: 1100px) {
        header {
            #header {
                .section {
                    #pt-logo {
                        background-image: url("https://www.palassets.com/web/responsive/images/paltalk-icon.svg");
                        width: 36px;
                        height: 30px;
                        margin-right: 14px;
                    }
                    #main-menu {
                        margin-top: 0;
                        li {
                            a {
                                margin-right: 14px;
                            }
                            &:last-child {
                                a {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and(max-width: 1025px) {
        header {
            position: relative;
            .section {
                #main-menu {
                    margin-top: 0;
                }
            }
        }
    }

    @media all and (max-width: 949px) {
        header {
            min-height: 56px;
            height: auto;
            max-height: inherit;
            #header {
                height: auto;
                min-height: 56px;
                max-height: inherit;
                .section {
                    padding: 0;
                    #nav-menu {
                        ul {
                            margin-top: 0;
                        }
                    }
                    #secondary-menu {
                        li {
                            display: inline-block;
                            .nickname {
                                display: none;
                            }
                        }
                    }
                    .menu-link {
                        margin: 26px 0 0;
                        background: transparent url("https://www.palassets.com/web/responsive/images/menu.svg") no-repeat 0 0;
                        width: 24px;
                        height: 20px;
                    }
                    #pt-logo {
                        background-image: url("https://www.palassets.com/web/responsive/images/paltalk_logo.svg");
                        width: 118px;
                        margin: 20px 24px;
                    }
                    .profile-info {
                        margin-right: 0;
                        margin-top: 0;
                        position: absolute;
                        right: 65px;
                        top: 18px;
                        a {
                            .nickname {
                                display: none;
                            }
                        }
                    }
                    a.menu-link {
                        float: left;
                        display: block;
                        padding: 26px 0;
                        position: absolute;
                        right: 24px;
                    }
                    #main-menu {
                        margin: 0;
                        width: 100%;
                        text-align: center;
                        border-top: 1px solid #d8d8d8;
                        li {
                            display: block;
                            clear: both;
                            margin: 24px 0 0;
                            &:last-child {
                                margin: 24px 0 32px;
                            }
                            a {
                                color: #5c7483;
                                float: none;
                                font-size: 2rem;
                                font-weight: 400;
                                line-height: 26px;
                                margin: 0;
                            }
                            ul.dropdown-menu {
                                position: relative;
                                padding-top: 0;
                                top: 0;
                                left: auto;
                                width: auto;
                                max-width: 225px;
                                margin: 0 auto;
                                li {
                                    float: none;
                                    margin: 0;
                                    &:first-child::before {
                                        border-width: 0;
                                    }
                                }
                            }
                        }
                    }
                    #secondary-menu {
                        margin: 0 0 32px 0 !important;
                        position: relative;
                        text-align: center;
                        right: 0;
                        top: 0;
                        width: 100%;
                        li {
                            display: inline-block;
                            float: none;
                        }
                        &.myaccount {
                            li {
                                &.my-account {
                                    display: inline-block;
                                    width: 100%;
                                    margin-left: 0;
                                    margin-top: -6px;
                                    margin-bottom: 24px;
                                    a {
                                        font-size: 2rem;
                                        color: #5c7483;
                                        &:hover {
                                            color: #f76652;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                                &#downloadpaltalk, .red-button {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    #nav-menu {
                        clear: both;
                        transition: all 2s ease-out .5s;
                        &.active {
                            max-height: 100%;
                        }
                    }
                    #main-wrapper {
                        margin-top: 0;
                    }
                }
            }
        }
        #nav-menu {
            overflow: hidden;
            max-height: 0;
        }
    }

    @media all and (max-width: 540px) {
        header {
            position: relative;
            #header {
                .section {
                    padding: 0;
                    #main-menu {
                        margin: 0;
                        width: 100%;
                        text-align: center;
                        border-top: 1px solid #d8d8d8;
                        li {
                            display: block;
                            clear: both;
                            margin: 24px 0 0;
                            a {
                                color: #5c7483;
                                float: none;
                                font-size: 2rem;
                                font-weight: 400;
                                line-height: 26px;
                                margin: 0;
                            }
                        }
                    }
                    #secondary-menu {
                        margin: 0 0 32px 0;
                        position: relative;
                        text-align: center;
                        width: 100%;
                    }
                    #nav-menu {
                        clear: both;
                        &.active {
                            max-height: 100%;
                        }
                    }
                }
            }
        }
        #nav-menu {
            overflow: hidden;
            max-height: 0;
        }
    }
</style>
