import axios from 'axios';

const api = axios.create({
    baseURL: 'https://client.paltalk.com/client/webservice',
    timeout: 30000,
    withCredentials: false,
});

export default {
    getClassicLinkStatus() {
        return api.get('/ptClassicAvail');
    },
    getGDPRPopupStatus() {
        return api.post('/getCountry');
    }
}
