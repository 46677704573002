<template>
    <div id="app">
        <app-header></app-header>
        <div id="page-wrapper">
            <router-view/>
            <back-to-top text="" visibleoffset="700" title="Back to Top"></back-to-top>
        </div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import BackToTop from 'vue-backtotop'
    import AppHeader from "./components/common/Header";
    import AppFooter from "./components/common/Footer";

    export default {
        name: 'app',
        components: {
            BackToTop,
            AppFooter,
            AppHeader
        }
    }
</script>

<style lang="scss">
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        src: url("https://www.palassets.com/fonts/Roboto/Roboto-Light.woff");
    }
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: url("https://www.palassets.com/fonts/Roboto/Roboto-Regular.woff");
    }
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        src: url("https://www.palassets.com/fonts/Roboto/Roboto-Medium.woff");
    }
    @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        src: url("https://www.palassets.com/fonts/Roboto/Roboto-Bold.woff");
    }

body, div, span, iframe, article, aside, canvas, details, embed, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, img, strong, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, footer, header, menu, nav, section, summary, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
html {
	font-size:0.625em;
	margin:0 !important;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
body {
	margin: 0;
	padding: 0;
	font-family:"Roboto", Helvetica, Arial, sans-serif;
	line-height: 1;
	overflow-x: hidden;
}
article, aside, details, header, footer, menu, nav, section {
	display: block;
}
blockquote {
	quotes: none;
	&:before {
		content: '';
		content: none;
	}
	&:after {
		content: '';
		content: none;
	}
}
a {
	text-decoration: none;
	outline: none;
	ie-dummy: expression(this.hideFocus=true);
	&:hover {
		text-decoration:none;
	}
}
strong {
	font-weight:700;
}
img {
	max-width: 100%;
}
ol, ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	td {
		vertical-align:top;
	}
}
q {
	quotes: none;
	&:before {
		content: '';
		content: none;
	}
	&:after {
		content: '';
		content: none;
	}
}
.clearfix:after {
	content:".";
	display:block;
	height:0;
	clear:both;
	visibility:hidden;
}
* html .clearfix{
	height:1%;
}
*:first-child + html .clearfix{
	min-height:1%;
}
iframe[name="google_conversion_frame"] {
	display:none;
}
.hide {
    display: none !important;
}

html, body {
    width: 100%;
    height: 100%;
}
#app {
    width: 100%;
    height: 100%;
}

#page-wrapper {
	margin: 56px auto 0 auto;
	min-height: calc(100% - 330px);
    #page {
        font-size: 110%;
        font-weight: 300;
        background: #f1f1f1;
        #main-wrapper {
            margin: 56px auto 0;
            width: 100%;
            #content {
                min-height:100px;
                .search_div {
                    margin-top:0;
                }
            }
        }
    }
}
#smartbanner {
	height: auto !important;
	position: relative !important;
	.sb-container {
		width: auto !important;
		transform: scale(1) !important;
	}
}

@media all and (max-width: 1025px) {
    #page-wrapper {
        margin-top: 0;
        #page {
            #main-wrapper {
                margin-top:0;
            }
        }
	}
}

.vue-back-to-top {
    bottom: 25px !important;
    right: 25px !important;
    .default {
        display: block;
        border-radius: 100%;
        background-color: #777;
        background-color: rgba(0, 0, 0, 0.5);
        width: 45px;
        height: 45px;
        border: none;
        span {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-width: 4px 4px 0 0;
            border-color: rgba(255, 255, 255, 0.75);
            border-style: solid;
            transform: rotate(315deg);
            position: relative;
            top: 15px;
        }
    }
}
</style>