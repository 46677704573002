<template>
    <div id="page">
        <vue-headful title="Chat Sites for People Meeting People | Paltalk"/>
        <div id="main-wrapper" class="clearfix">
            <div id="main-content">
                <search-bar :onlineMembersCount="onlineMembersCount" :keywordParent="keyword"
                            @search="search" @updateKeyword="updateKeyword"/>
                <top-ad/>
                <div id="content">
                    <div class="myanchor" id="myanchor"></div>
                    <div class="section">
                        <div class="sticky-search" id="sticky-search">
                          <div class="filter-container" id="filter-container">
                            <div class="panel">
                                <div class="panel-title" id="panel-title" @click="onScreenResize()">
                                    <a href="javascript:void(0)" class="down-arrow"></a>
                                    <h5><i class="filter-icon"></i> Members Search/Filters</h5>
                                </div>
                                <div class="panel-content" :class="{'active': isMobileView}">
                                    <fieldset>
                                        <div class="label">Gender:</div>
                                        <v-select v-model="gender" class="form-field gender"
                                                  :options="genderOpt" @input="getGender">
                                            <template slot="option" slot-scope="option">
                                                {{ option.label }}
                                            </template>
                                        </v-select>
                                    </fieldset>
                                    <fieldset>
                                        <div class="label">Age:</div>
                                        <div class="form-field">
                                            <div class="age-range">
                                                <input type="text" size="2" maxlength="2" v-model="age[0]"
                                                       readonly>
                                                <input type="text" size="2" maxlength="2" v-model="age[1]"
                                                       readonly>
                                            </div>
                                            <div class="age-slider">
                                                <vue-slider ref="slider" v-model="age"
                                                            :min=18 :max=99
                                                            :tooltip="ageOptions.tooltip"
                                                            :tooltip-dir="ageOptions.tooltipDir"
                                                            :style="ageOptions.style"
                                                            @drag-end="getAge">
                                                </vue-slider>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div class="label">Country:</div>
                                        <v-select v-model="country" class="form-field country"
                                                  :options="countriesOpt"
                                                  :searchable="true"
                                                  @input="getCountry">
                                            <template slot="option" slot-scope="option">
                                                {{ option.label }}
                                            </template>
                                        </v-select>
                                    </fieldset>
                                </div>
                                <!--
                                <div class="panel-footer" :class="{'active': isMobileView}">
                                    <p id="error-msg" class="error-msg search"></p>
                                    <a class="btn-apply" @click="filterUsers">Apply</a>
                                    <a class="btn-clearall" @click="clearFilters">Clear All</a>
                                </div>
                                -->
                            </div>
                          </div>
                        </div>

                        <div class="main-content">
                            <section id="search-filter">
                                <left-ad/>
                            </section>
                            <section id="search-result">
                                <div class="content">
                                    <ul id="top-members-ul">
                                        <profile-card v-for="user in users"
                                                      v-bind:key="user.id"
                                                      :user="user"
                                                      :imgPrefix="imgUrl"
                                                      :countryImgPrefix="countryImgUrl"/>
                                    </ul>
                                </div>
                                <infinite-loading @infinite="infiniteHandler" ref="infiniteLoading"
                                                  spinner="circles">
                                    <div slot="no-more" v-show="page > 1" class="no-more">
                                        <p>End of Content</p>
                                    </div>
                                    <div slot="no-results" class="no-results">
                                        <div class="sad"><i class="icon-smily"></i></div>
                                        <p>Your search did not yield any results</p>
                                    </div>
                                </infinite-loading>
                            </section>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import InfiniteLoading from 'vue-infinite-loading';
    import vSelect from 'vue-select';
    import VueSlider from 'vue-slider-component';
    import SearchBar from '../components/common/SearchBar';
    import ProfileCard from "../components/widget/ProfileCard";
    import TopAd from "../components/googleads/Top";
    import LeftAd from "../components/googleads/Left";
    import LiveSearchApi from '../services/LiveSearchApi';
    import PaltalkConnectApi from '../services/PaltalkConnectApi';
    import countries from '../services/countries';

    export default {
        name: 'searchPeople',
        data: () => ({
            isMobileView: false,
            onlineMembersCount: '000000',
            pages: 0,
            page: 0,
            keyword: '',
            gender: {label: 'Any', value: ''},
            country: {label: 'WorldWide', value: ''},
            age: [18, 99],
            imgUrl: '',
            users: [],
            countryImgUrl: {},
            ageOptions: {
                tooltip: false,
                tooltipDir: ['bottom', 'top'],
                piecewise: false,
                style: {'width': '100%'}
            },
            genderOpt: [
                {label: 'Any', value: ''},
                {label: 'Male', value: 'M'},
                {label: 'Female', value: 'F'}
            ],
            countriesOpt: countries,
            t: 0
        }),
        components: {
            LeftAd,
            TopAd,
            SearchBar,
            ProfileCard,
            vSelect,
            VueSlider,
            InfiniteLoading
        },
        methods: {
            getGender() {
                this.timeoutSearch();
            },
            getCountry() {
                this.timeoutSearch();
            },
            getAge() {
                this.timeoutSearch();
            },
            toggleFilter() {
                this.isMobileView = !this.isMobileView;
            },
            timeoutSearch() {
                clearTimeout(this.t);
                this.t = setTimeout(this.filterUsers, 500);
            },
            updateKeyword(keyword) {
                this.keyword = keyword;
            },
            search(keyword) {
                this.page = 0;
                this.pages = 0;
                this.keyword = keyword;
                this.users = [];
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });

            },
            filterUsers() {
                this.page = 0;
                this.pages = 0;
                this.users = [];
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
            },
            clearFilters() {
                this.keyword = '';
                this.gender = {label: 'Any', value: ''};
                this.country = {label: 'WorldWide', value: ''};
                this.age = [18, 99];
                this.page = 0;
                this.pages = 0;
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
            },
            infiniteHandler($state) {
                let filters = {
                    gender: this.gender.value,
                    fromAge: this.age[0],
                    toAge: this.age[1],
                    country: this.country.value,
                    keyword: (this.keyword) || ''
                };
                this.$router.replace({name: 'searchPeople', query: filters});
                this.page += 1;
                this.pages += 1;
                filters.page = this.page;
                LiveSearchApi.searchAllUsers(filters)
                    .then(resp => {
                        const response = (resp.data) || {};
                        if (Object.keys(response).length > 0) {
                            this.users.push(...response.hits.users);
                            this.imgUrl = response.hits['imgPrefix'];
                            this.countryImgUrl = response.hits['countryPrefix'];
                            $state.loaded();
                            if (this.users.length < 25) {
                                $state.complete();
                            } else if (this.pages === 10) {
                                $state.complete();
                            }
                        } else {
                            $state.complete();
                        }
                    })
                    .catch(e => {
                        if (axios.isCancel(e)) {
                            return;
                        }
                        $state.complete();
                    });
            },
            getOnlineStats() {
                PaltalkConnectApi.getOnlineStats()
                    .then(resp => {
                        this.onlineMembersCount = resp.data['total_users'].toString();
                    });
            },
            scrollPage() {
                  let scrolledValue = window.scrollY;
                  let myanchorPos =  document.getElementById('myanchor').offsetTop;
                  let elementOffset;
                  if(window.innerWidth > 1025) {
                     elementOffset = myanchorPos - document.getElementById("header").offsetHeight;
                     if(scrolledValue > elementOffset) {
                          document.body.classList.add("sticky");
                          document.getElementById("sticky-search").style.top = document.getElementById("header").offsetHeight+'px'

                      }else {
                          document.body.classList.remove("sticky");
                          document.getElementById("sticky-search").style.top = 0 +'px'
                      }
                  } else {
                      elementOffset = myanchorPos;
                      if(scrolledValue > elementOffset) {
                          document.body.classList.add("sticky");
                          document.getElementById("sticky-search").style.top = 0 +'px'
                      } else {
                          document.body.classList.remove('sticky');
                          document.getElementById("sticky-search").style.top = 0 +'px'
                      }
                  }
            },
            onScreenResize() {
                if(window.innerWidth > 992) {
                  return;
                }else {
                  if(document.getElementById('panel-title').classList.contains('active')) {
                    document.getElementById('panel-title').classList.remove('active')
                  } else {
                    document.getElementById('panel-title').classList.add('active')
                  }
                }
            }
        },
        created() {
            this.users.length = 0;
            this.page = 0;
            // this.getOnlineStats();

            window.addEventListener('scroll', ()=>{
              this.scrollPage()
            });
        },
        mounted() {
            const params = (this.$route.query) || {};
            Object.keys(params).forEach(key => {
                if (params[key] !== '') {
                    if (key === 'gender') {
                        this.genderOpt.forEach(g => {
                            if (g.value.toUpperCase() === params[key].toUpperCase()) {
                                this.gender = g;
                                return;
                            }
                        });
                    } else if (key.toLowerCase() === 'country') {
                        if (params[key].toUpperCase() === 'WW') {
                            this.country = {label: 'WorldWide', value: ''};
                        } else {
                            this.countriesOpt.forEach(c => {
                                if (c.value.toUpperCase() === params[key].toUpperCase()) {
                                    this.country = c;
                                    return;
                                }
                            });
                        }
                    } else if (key === 'fromAge') {
                        const age0 = (params['fromAge']) || '';
                        const age1 = (params['toAge']) || '';
                        if (parseInt(age0, 10) > 0 && parseInt(age1, 10) > 0) {
                            this.age = [parseInt(age0, 10), parseInt(age1, 10)]
                        }
                    } else if (key === 'keyword') {
                        const k = (params[key]) || '';
                        if (k.trim().length >= 3 && k.trim().length <= 40) {
                            this.keyword = k;
                        }
                    }
                }
            });
        }
    }
</script>

<style lang="scss" scoped>
    .filter-icon {
        display: inline-block;
        vertical-align: middle;
        background-color: transparent;
        background-image: url('https://www.palassets.com/sprites/filter_icon.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 12px;
        height: 11px;
    }

    #content {
        padding: 30px 0 50px 0;
        margin: 0;
        float: none;
        min-width: 1px;
        .section {
            width: 100%;
            margin: 0 auto;
            padding: 0 31px;
            max-width: 1312px;
            box-sizing: border-box;
            .page-title {
                width: 100%;
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 20px;
                position: relative;
                h4 {
                    font-size: 20px;
                    font-weight: 300;
                    color: #494949;
                }
                .ip-address {
                    position: absolute;
                    right: 0;
                    top: 5px;
                    color: #f1f1f1;
                }
            }
        }
        .panel {
            background: #fff;
            .title {
                h4 {
                    font-size: 20px;
                    font-weight: 300;
                    color: #494949;
                    span {
                        color: #999;
                        margin-left: 10px;
                    }
                }
            }
            .footer {
                text-align: center;
                padding: 9px 0 13px 0;
                .btn-viewmore, .btn-loadmore {
                    display: inline-block;
                    vertical-align: middle;
                    color: #94A0A8;
                    background: #fff;
                    font-size: 14px;
                    line-height: 18px;
                    padding: 5px 15px;
                    border-radius: 5px;
                    border: solid 1px #EDEDED;
                    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    #search-filter {
        width: 340px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
        float: left;

    }

    .filter-container {
      .panel {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        background: #fff;
        margin-bottom: 0;
        padding: 0;

        .panel-title {
          margin: 0 20px;
          padding: 15px 0;
          h5 {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.25;
            color: #494949;
            display: inline-block;
            vertical-align: middle;

            i {
              margin-right: 5px;
            }
          }
        }

        .panel-content {
          padding: 13px 0;
          border-top: none;
          margin: 0 15px;
          display: flex;
          border-top: solid 1px #e3e3e3;
          fieldset {
            margin: 0;

            .label {
              font-size: 15px;
              color: #858585;
              line-height: 1.25;
              font-weight: 300;
              display: inline-block;
              vertical-align: middle;
              width: 65px;
            }

            .form-field {
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 70px);
              position: relative;

              .online-filter {
                display: inline-block;
                vertical-align: middle;
                background: #6bda3a;
                width: 20px;
                height: 12px;
                border-radius: 10px;
                position: relative;
                top: 1px;
                cursor: pointer;

                span {
                  background: #fff;
                  height: 10px;
                  width: 10px;
                  border-radius: 100%;
                  position: absolute;
                  right: 1px;
                  top: 1px;
                }

                &.off {
                  background: rgba(0, 0, 0, 0.15);

                  span {
                    left: 1px;
                    right: auto;
                  }
                }

                &.disabled {
                  background: rgba(0, 0, 0, 0.07);

                  span {
                    left: 1px;
                    right: auto;
                  }
                }
              }

              .age-range {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                margin-bottom: 6px;

                input[type=text] {
                  background: #f8f8f8;
                  border: solid 1px rgba(0, 0, 0, .07);
                  border-radius: 3px;
                  font-size: 15px;
                  font-family: roboto, Helvetica, Arial, sans-serif;
                  font-weight: 300;
                  line-height: 18px;
                  color: #494949;
                  padding: 8px 0 6px;
                  text-align: center;
                  width: 60px;
                  &:last-child {
                    float: right;
                  }
                }
              }

              .age-slider {
                width: 100%;
              }
            }
          }
        }

        .panel-footer {
          text-align: center;
          padding: 17px 0 0 0;
          margin: 0 20px;
          border-top: solid 1px #e3e3e3;

          .error-msg {
            color: #FF745F;
            margin-bottom: 10px;
          }

          .btn-apply, .btn-clearall {
            display: inline-block;
            vertical-align: middle;
            padding: 6px 20px;
            border-radius: 19px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
          }

          .btn-apply {
            color: #fff;
            background: #5ab9fb;
            border: solid 1px #5ab9fb;

            &:hover {
              background: #71c5ff;
              border-color: #71c5ff;
            }

            &:active {
              background: #51b0f2;
              border-color: #51b0f2;
            }
          }

          .btn-clearall {
            color: #7F8A92;
            border: solid 1px #c8c8c8;
            margin-left: 7px;

            &:hover {
              background: #71c5ff;
              border-color: #71c5ff;
              color: #fff;
            }

            &:active {
              background: #51b0f2;
              border-color: #51b0f2;
              color: #fff;
            }
          }
        }
      }
    }

    #search-result {
        width: 100%;
        max-width: 885px;
        display: inline-block;
        vertical-align: middle;
        float: left;
        .content {
            width: 100%;
            margin: 0 0 25px;
            ul {
                display: grid;
                grid-template-columns: repeat(auto-fill, 160px);
                column-gap: 21px;
                row-gap: 21px;
                li {
                    margin: 0;
                    position: relative;
                    z-index: 1;
                    &.mobile-ad {
                      width: 100%;
                      max-width: 100%;
                      min-height: 50px;
                      text-align: center;
                      margin-right: auto;
                      background: transparent;
                      display: flex;
                      justify-content: center;
                      grid-column: 1 / 3;
                    }
                }
            }
        }
        .footer {
            clear: both;
            padding-top: 25px;
            border-top: solid 1px #c8c8c8;
            text-align: center;
            .btn-seemore {
                display: inline-block;
                padding: 6px 20px;
                border-radius: 19px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #7F8A92;
                border: solid 1px #c8c8c8;
                &:hover {
                    background: #71c5ff;
                    border-color: #71c5ff;
                    color: #fff;
                }
                &:active {
                    background: #51b0f2;
                    border-color: #51b0f2;
                    color: #fff;
                }
            }
        }
        .no-results {
            padding-top: 100px;
            text-align: center;
            .sad {
                margin-bottom: 20px;
                .icon-smily {
                    display: inline-block;
                    background: transparent url("https://www.palassets.com/peopleweb/miniprofile/images/sprite-miniprofile.svg?v=1707") no-repeat 0 -33px;
                    width: 51px;
                    height: 51px;
                }
            }
            p {
                font-size: 18px;
                color: #999;
                font-weight: 400;
                line-height: 1.3;
            }
        }
        .no-more {
            text-align: center;
            position: relative;
            line-height: 18px;
            p {
                display: inline-block;
                font-size: 13px;
                font-weight: 400;
                color: #999;
                background: #f2f2f2;
                padding: 1px 10px;
                position: relative;
                z-index: 1;
            }
            &:before {
                content: '';
                display: inline-block;
                height: 1px;
                width: 80%;
                position: absolute;
                left: 10%;
                top: 50%;
                background: #ccc;
            }
        }
    }

    @media all and (min-width: 768px) {
      #content {
        .main-content {
          #search-result {
            .content {
              ul {
                li {
                  &.mobile-ad {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media all and (min-width: 768px) and (max-width: 1350px) {
        #content {
            .main-content {
                display: table;
                #search-filter {
                    display: table-cell;
                    vertical-align: top;
                    padding-right: 25px;
                    float: none;
                    width: 340px;
                    min-width: 340px;
                }
                #search-result {
                    display: table-cell;
                    vertical-align: top;
                    float: none;
                }
            }
        }
    }

    @media all and (max-width: 1024px) {
        #content {
            .section {
                padding: 0 15px;
            }
        }
    }

    @media all and (max-width: 767px) {
        #content {
            .section {
                display: inline-block;
                #search-filter {
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 0;
                    width: 100%;
                    margin-bottom: 20px;

                }
                #search-result {
                    .content {
                      ul {
                          justify-content: center;
                        li {
                          &.mobile-ad {
                            & + li {
                              clear: both;
                            }
                          }
                        }
                      }
                    }
                }
            }
        }
    }

    @media all and (max-width: 400px) {
        #content {
            .main-content {
                #search-result {
                    .content {
                        ul {
                            grid-template-columns: repeat(auto-fill, calc(50% - 15px));
                            column-gap: 15px;
                            row-gap: 15px;
                            li {
                                max-width: 160px;
                                .thumb {
                                    .crown {
                                        top: 88%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 360px) {
        #content {
            .section {
                padding: 0 10px;
            }
        }
    }

    .myanchor {
      width: 100%;
      height: 1px;
    }
    .sticky-search {
      width: 100%;
      transition: width 0.2s ease-in-out;
      background: #fff;
      margin-bottom: 45px;
      .section {
        padding: 0 12px !important;
      }
    }

    .sticky {
      .sticky-search {
        position: fixed;
        z-index: 99;
        top: 56px;
        left: 0;
        border-top: solid 1px #e3e3e3;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
        ~ .main-content {
          padding-top: 125px;
        }
        .filter-container {
          display: block;
          margin: 0 auto;
          max-width: 1288px;
          padding: 0 12px;
          box-sizing: border-box;
        }
      }
    }

    .filter-container {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      background: #fff;
      padding: 0;
      .panel {
        .panel-title {
          margin: 0 20px;
          padding: 15px 0;

          .down-arrow {
            display: none;
            float: right;
            position: relative;
            top: 9px;
            cursor: pointer;
          }

          h5 {
            font-size: 18px;
            font-weight: 300;
            color: #4a4a4a;
            display: inline-flex;
            align-items: center;

            i {
              margin-right: 5px;
            }
          }
        }

        .panel-content {
          padding: 13px 0;
          margin: 0 15px;
          display: flex;

          fieldset {
            margin: 0;
            width: 25%;
            float: left;
            padding: 0 7px;

            .label {
              font-size: 15px;
              color: #858585;
              font-weight: 300;
              width: 65px;
              display: inline-block;
              vertical-align: middle;
            }

            .form-field {
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 70px);
              position: relative;

              .age-range {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                margin-bottom: 6px;

                input[type="text"] {
                  text-align: center;
                }
              }

              &.gender {
                width: calc(100% - 70px);
              }
            }
          }
        }
      }
    }

    @media all and (min-width: 1026px) {
      .filter-container {
        .panel {
          .panel-content {
            fieldset {
              &:nth-child(2) {
                .label {
                  position: relative;
                  top: -5px;
                }
              }

              &:nth-child(3) {
                .label {
                  position: relative;
                  top: -1px;
                }
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 1025px) {
      .filter-container {
        .panel {
          .panel-content {
            fieldset {
              .label {
                width: 100%;
                margin-bottom: 5px;
              }

              .form-field {
                width: 100%;
                position: relative;

                &.gender {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 992px) {
      .filter-container {
        padding: 0;
        box-sizing: border-box;
        .panel {
          .panel-title {
            .down-arrow {
              display: block;
              float: right;
              position: relative;
              top: 9px;
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 7px solid #333;
            }

            & + .panel-content {
              max-height: 0;
              padding: 0;
              overflow: hidden;
              transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
              border-top: none;
            }

            &.active {
              .down-arrow {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #000;
                border-top: none;
              }

              & + .panel-content {
                border-top: solid 1px #e3e3e3;
                max-height: 99em;
                padding: 13px 0;
                overflow: inherit;
              }
            }
          }

          .panel-content {
            display: block;

            fieldset {
              width: 31.2%;
              padding: 0 1%;
            }
          }

        }
      }
    }

    @media all and (max-width: 525px) {
      .filter-container {
        .panel {
          .panel-content {
            fieldset {
              width: 95% !important;
              margin: 0 auto 15px !important;
              padding-left: 7px !important;

              .form-field {
                .age-range {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
</style>
<style lang="scss">
    .v-select {
        font-family: "Roboto", Helvetica, Arial, sans-serif;
        position: relative;
        /*z-index: 9999999 !important;*/
        .vs__selected-options {
            max-width: 100%;
        }
        .dropdown-toggle {
            background: #f8f8f8 !important;
            border: solid 1px rgba(0, 0, 0, .07) !important;
            border-radius: 3px !important;
            white-space: nowrap;
            height: 35px;
            color: #494949;
            cursor: pointer !important;
            position: relative;
            .selected-tag {
                padding: 8px 11px;
                width: calc(100% - 15px);
                height: auto;
                line-height: 18px;
                font-size: 15px;
                font-weight: 300;
                color: #494949;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border: none;
                margin: 0;
                border-radius: 0;
            }
            .clear {
                display: none;
            }
        }
        input[type=search] {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute !important;
            left: 0;
            cursor: pointer;
            font-family: "Roboto", Helvetica, Arial, sans-serif;
            font-size: 15px !important;
            font-weight: 300 !important;
            line-height: 18px !important;
            color: #494949 !important;
            padding: 7px 32px 10px 13px !important;
            border: none;
            margin: 0 !important;
            width: 100% !important;
        }
        .dropdown-menu {
            position: absolute !important;
            top: 110% !important;
            margin-top: 0 !important;
            background: #fff !important;
            border-radius: 3px !important;
            border: 1px solid #71c5ff !important;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3) !important;
            padding: 0;
            max-height: 200px !important;
            z-index: 9999999 !important;
            li {
                font-size: 15px;
                line-height: 22px;
                font-weight: 300;
                padding: 0;
                display: inline-block;
                width: 100%;
                white-space: normal;
                word-wrap: break-word;
                a {
                    padding: 10px;
                    color: rgba(0, 0, 0, .7) !important;
                    white-space: normal;
                    word-wrap: break-word;
                    &:hover {
                        background: #e7f5fc !important;
                        color: rgba(0, 0, 0, .7);
                    }
                }
                &.no-options {
                    padding: 10px;
                    color: rgba(0, 0, 0, .7);
                    text-align: left;
                }
            }
            & > .highlight > a {
                background: #e7f5fc !important;
            }
        }
        .open-indicator {
            position: absolute;
            width: 7px;
            top: 12px;
            right: 10px;
            height: 7px;
            &::before {
                width: 7px !important;
                height: 7px !important;
                border-width: 2px 2px 0 0 !important;
                border-color: rgba(0, 0, 0, 0.2) !important;
            }
        }
        &.open {
            .open-indicator {
                top: 15px !important;
            }
        }
    }

    .vue-slider-component {
        padding: 4px !important;
        .vue-slider {
            height: 4px !important;
        }
        .vue-slider-process {
            background: #00a3ff !important;
            border-radius: 4px;
        }
        .vue-slider-dot {
            width: 8px !important;
            height: 8px !important;
            top: -2px !important;
            background: #00a3ff !important;
            box-shadow: none !important;
            border-radius: 100%;
            .vue-slider-dot-handle {
                background-color: transparent;
                box-shadow: none;
            }
        }
        &.vue-slider-horizontal {
            .vue-slider-dot {
                left: 4px;
            }
        }
    }

    @media all and (max-width: 767px){
      .age-slider {
        height: 20px;

        .vue-slider-component {
          width: calc(100% - 15px);

          .vue-slider {
            height: 7px !important;
          }

          .vue-slider-dot {
            width: 15px !important;
            height: 15px !important;
            top: -5px !important;
            border-radius: 5px !important;
          }
        }
      }
    }
</style>
