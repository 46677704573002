<template>
    <footer>
        <div class="section clearfix">
            <div class="region region-footer">
                <div id="block-menu-menu-footer-links" class="block block-menu">
                    <h2>Footer Links</h2>
                    <div class="content">
                        <ul class="menu">
                            <li class="first expanded">
                                <span class="nolink">Paltalk</span>
                                <ul class="menu">
                                    <li class="leaf">
                                        <a href="javascript:void(0);" @click="directdownload" id="footer-download-link">Paltalk for Desktop</a>
                                    </li>
                                    <li class="leaf" v-if="showClassic"><a
                                            href="https://www.paltalk.com/download/help/cg.html?refc=132223">Paltalk Classic</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/mobile">Paltalk Mobile</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/vgifts">Virtual Gifts</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/members">Search for Friends</a>
                                    </li>
                                    <li class="leaf"><a href="https://www.paltalk.com/free-chat-rooms">Search for
                                        Rooms</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/partnerships">Partner with Us</a>
                                    </li>
                                    <li class="leaf"><a href="https://paltalkstore.com/">Merchandise</a></li>
                                </ul>
                            </li>
                            <li class="expanded">
                                <span class="nolink">About Us</span>
                                <ul class="menu">
                                    <li class="leaf"><a href="https://investors.paltalk.com/news">Newsroom</a></li>
                                    <li class="leaf"><a href="https://investors.paltalk.com/">Investors</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/leadership">Leadership</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/careers">Careers</a></li>
                                </ul>
                            </li>
                            <li class="expanded">
                                <span class="nolink">Social</span>
                                <ul class="menu">
                                    <li class="leaf"><a href="https://www.paltalk.com/blog">Blog</a></li>
                                    <li class="leaf"><a href="https://www.facebook.com/Paltalk">Facebook</a></li>
                                    <li class="leaf"><a href="https://www.twitter.com/paltalk">Twitter</a></li>
                                    <li class="leaf"><a href="https://www.instagram.com/paltalk/">Instagram</a></li>
                                </ul>
                            </li>
                            <li class="last expanded">
                                <span class="nolink">Support</span>
                                <ul class="menu">
                                    <li class="leaf"><a href="https://support.paltalk.com">Support Home</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/contact_paltalk.shtml">Contact Us</a></li>
                                    <li class="leaf"><a :href="uninstallUrl">Uninstall</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/accessibility">Accessibility</a></li>
                                </ul>
                            </li>
                            <li class="expanded">
                                <span class="nolink">Our Products</span>
                                <ul class="menu">
                                    <li class="leaf"><a href="https://www.camfrog.com">Camfrog</a></li>
                                    <li class="leaf"><a href="https://tinychat.com">Tinychat</a></li>
                                    <li class="leaf"><a href="https://www.vumber.com/">Vumber</a></li>
                                    <li class="leaf"><a href="https://www.videoconference.com/">Video Conference</a></li>
                                    <li class="leaf"><a href="https://www.manycam.com/">ManyCam</a></li>
                                </ul>
                            </li>
                            <li class="last expanded">
                                <span class="nolink">Legal</span>
                                <ul class="menu">
                                    <li class="leaf"><a href="https://www.paltalk.com/terms">Terms of Service</a></li>
                                    <li class="leaf"><a href="https://www.paltalk.com/privacy">Privacy Policy</a></li>
                                    <li class="leaf" v-if="showGDPRSettings"><a href="https://www.paltalk.com/cookie-policy">Cookie Policy</a></li>
                                    <li class="leaf" v-if="showGDPRSettings"><a class="ot-sdk-show-settings" id="ot-sdk-btn">Cookie Settings</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="block-locale-language" class="block block-locale" :class="{'open': toggleLang}">
                    <h2>Languages</h2>
                    <div class="content">
                        <div class="language-dropdown">
                            <span class="valueholder" @click="toggleLangSelect">{{selected.label}}</span>
                            <ul class="language-switcher-locale-url notranslate" @mouseleave="toggleLangSelect">
                                <li v-for="(lang, index) in languages" v-bind:key="index">
                                    <a class="language-link" :href="createHref(lang)"
                                       :lang="lang.code">{{lang.label}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section clearfix">
            <span class="copyright">Copyright &copy; {{currentYear}} Paltalk, Inc. All rights reserved.</span>
        </div>
        <iframe id="download_iframe" style="display:none;"></iframe>
    </footer>
</template>

<script>
import changeCase from 'change-case';
import Browser from 'bowser';
import Languages from '../../services/languages';
import clientService from '../../services/ClientServiceApi';

const year = new Date().getFullYear();
    const host = location.host.startsWith('localhost') ? 'browse.paltalk.com' : location.host;
    const EU_COUNTRIES = "BE,BG,CZ,DK,DE,EE,IE,ES,FR,HR,IT,CY,LV,LT,LU,HU,MT,NL,AT,PL,PT,RO,SI,SK,FI,SE,GB,GR";
    const macUninstall = 'https://support.paltalk.com/support/solutions/articles/47001132610-uninstall-paltalk-from-a-mac-pc';
    const androidUninstall = 'https://support.paltalk.com/support/solutions/articles/47000861155-uninstall-paltalk-from-your-android-phone';
    const windowsUninstall = 'https://support.paltalk.com/support/solutions/articles/47001132604-uninstall-paltalk-from-a-windows-pc';
    const iosUninstall = 'https://support.paltalk.com/support/solutions/articles/47000861154-uninstall-paltalk-from-your-iphone';
    export default {
        name: 'app-footer',
        data: () => ({
            currentYear: year,
            toggleLang: false,
            languages: Languages,
            showClassic: false,
            showGDPRSettings: false,
            selected: {code: 'en', label: 'English'},
            uninstallUrl: windowsUninstall,
        }),
        methods: {
            toggleLangSelect() {
                this.toggleLang = !this.toggleLang;
            },
            getLangFrmUrl() {
                let ret = 'en';
                const lang = location.hostname.split('.')[0];
                this.languages.forEach(value => {
                    if (value.code === lang) {
                        ret = lang;
                        return;
                    }
                });
                return ret;
            },
            getQueryStr() {
                let queryStr = '';
                const params = (this.$route.query) || {};
                Object.keys(params).forEach((key) => {
                    queryStr += `&${key}=${params[key]}`;
                });
                if (queryStr !== '') {
                    queryStr = queryStr.substring(1);
                }
                return queryStr;
            },
            createHref(lang) {
                const qStr = this.getQueryStr();
                if (qStr !== '') {
                    return `${location.protocol}//${lang.code}.${host}/#/?${this.getQueryStr()}`;
                }
                return `${location.protocol}//${lang.code}.${host}/#/`;
            },
            changeCookieSettingLabel() {
              setTimeout(function () {
                document.getElementById('ot-sdk-btn').innerText = 'Cookie Settings';
              }, 2500);
            },
            onLoad() {
                const that = this;
                clientService.getClassicLinkStatus()
                    .then(response => {
                        that.showClassic = (response.data || {}).pt_classic_avail || false;
                    });
                clientService.getGDPRPopupStatus()
                    .then(response => {
                        const country = (response.data || {}).country_code || 'US';
                        if (EU_COUNTRIES.indexOf(changeCase.upperCase(country)) !== -1) {
                            const that = this;
                            that.showGDPRSettings = true;
                            const ot_script = document.createElement('script');
                            ot_script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
                            // ot_script.setAttribute('data-domain-script', '48119cbe-b6ce-4726-9b81-6707ec4b77c3-test');
                            ot_script.setAttribute('data-domain-script', 'c2a7e04e-15f9-4d92-bf41-a0bae4676bc7');
                            ot_script.setAttribute('charset', 'UTF-8');
                            if (ot_script.readyState) {
                              ot_script.onreadystatechange = function () {
                                if (this.readyState == 'complete' || this.readyState == 'loaded') {
                                  that.changeCookieSettingLabel();
                                }
                              };
                            } else {
                              ot_script.onload = function () {
                                setTimeout(function () {
                                  that.changeCookieSettingLabel();
                                }, 1000);
                              }
                            }
                            (document.getElementsByTagName('head')[0] || document.documentElement).appendChild(ot_script);
                            const createOptanonWrapper = document.createElement('script');
                            createOptanonWrapper.innerText = 'function OptanonWrapper() {}';
                            document.body.appendChild(createOptanonWrapper);
                        }
                    });
            },
            directdownload() {
                var platform = navigator.platform.toUpperCase(),
                  agentName = navigator.userAgent.toUpperCase();
                    if (platform.indexOf('MAC') !== -1) {
                        var downloadExeMac = "https://downloads.paltalk.com/download/osx/paltalk.dmg";
                        document.getElementById('download_iframe').setAttribute('src', downloadExeMac);
                    } else if (agentName.indexOf("WIN") != -1) {
                        var downloadExeWin = "https://downloads.paltalk.com/download/win/PaltalkSetup.exe";
                        document.getElementById('download_iframe').setAttribute('src', downloadExeWin);
                    }
                    setTimeout(function () {
                        var finalUrl = 'https://register.paltalk.com/reg/ControllerServlet?RequestId=Register.ThankYou&src=browseapp'; 
                        window.location.href = finalUrl;
                    }, 2000);
            },
        },
        created() {
            const browser = Browser.getParser(window.navigator.userAgent);
            const os = changeCase.lowerCase(((browser || {}).getOS() || {}).name || '');
            if (os.indexOf('mac') !== -1) {
                this.uninstallUrl = macUninstall;
            } else if (os.indexOf("android") !== -1) {
                this.uninstallUrl = androidUninstall;
            } else if (os.indexOf("iphone") !== -1 || os.indexOf("ipad") !== -1) {
                this.uninstallUrl = iosUninstall;
            } else {
                this.uninstallUrl = windowsUninstall;
            }
            this.onLoad();
            const lang = this.getLangFrmUrl();
            Object.keys(Languages).forEach(key => {
                if (lang === key) {
                    this.selected = Languages[key];
                }
            });
        }
    }
</script>

<style lang="scss" scoped>
    footer {
        width: 100%;
        height: auto;
        min-width: 1px;
        margin: 0 auto;
        background: #0082c8;
        padding: 48px 0;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.9);
        box-sizing: border-box;

        div, a, li, section {
            box-sizing: border-box;
        }

        .section {
            padding: 0 16px;
            width: 100%;
            margin: 0 auto;
            max-width: 1312px;
        }

        .content {
            background: none;
        }

        h2 {
            display: none;
        }

        .nolink, .link {
            font-size: 16px;
            font-weight: 300;
            margin: 0 0 8px 0;
            display: block;
            color: rgba(255, 255, 255, 0.9);

            &:hover {
                text-decoration: none;
            }
        }

        .menu {
            li {
                margin: 0;
                padding-bottom: 4px;
                padding-top: 0.2em;
                list-style: none;
                background: none;
                display: block;
                float: none;
                border-left: none;

                &.expanded {
                    width: 16.66%;
                    margin: 0;
                    padding: 0 16px;
                    float: left;
                    background: none;
                    border-radius: 0;
                }
            }

            a {
                color: #e6e4e4;
                font-size: 12px;
                line-height: 18px;
                color: rgba(255, 255, 255, 0.75);
                padding: 0;
                text-align: left;

                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }

                &.link {
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.9);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .copyright {
            float: left;
            display: block;
            margin: 32px 0 0 16px;
            font-size: 12px;
            line-height: normal;
        }

        ul.menu li {
            margin-left: 0;

            a {
                float: none;
                margin-left: 0;
            }
        }

        #block-locale-language {
            width: 25%;
            padding: 0 16px;
            position: relative;
            max-width: 230px;
            clear: both;

            .content {
                margin-top: 20px;
                width: 100%;
                display: inline-block;
                position: relative;
                background: transparent !important;

                .language-dropdown {
                    .valueholder {
                        cursor: pointer;
                        float: left;
                        border-radius: 3px;
                        border: solid 1px rgba(255, 255, 255, 0.5);
                        padding: 6px 11px 6px 37px;
                        background: transparent;
                        width: 198px;
                        box-sizing: border-box;
                        font-size: 14px;
                        color: #fff;
                        line-height: 1.7;
                        display: block;
                        position: relative;

                        &:before {
                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            position: absolute;
                            left: 14px;
                            margin-top: 4px;
                            width: 16px;
                            height: 16px;
                            background: transparent url('https://www.palassets.com/web/responsive/images/language-icon.svg') no-repeat 0 0;
                            background-size: contain;
                        }

                        &:after {
                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            position: absolute;
                            right: 12px;
                            top: 13px;
                            background: transparent url('https://www.palassets.com/web/responsive/images/lang-dropdown.png') no-repeat 0 0;
                            width: 11px;
                            height: 11px;
                        }

                        &:hover {
                            background-color: rgba(255, 255, 255, 0.9);
                            border-color: rgba(255, 255, 255, 0.01);
                            color: #0082c8;

                            &:before {
                                background-image: url('https://www.palassets.com/web/responsive/images/language-icon-hover.svg');
                            }

                            &:after {
                                background-position: 0 -22px;
                            }
                        }

                        &:active, &.active {
                            background-color: #f8f8f8;
                            border-color: rgba(255, 255, 255, 0.7);
                            color: #0082c8;

                            &:before {
                                background-image: url('https://www.palassets.com/web/responsive/images/language-icon-hover.svg');
                            }

                            &:after {
                                background-position: 0 -44px;
                            }
                        }
                    }
                }
            }

            ul {
                width: 198px;
                border-radius: 3px;
                background-color: #fff;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
                border: solid 1px #71c5ff;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                bottom: 100%;
                max-height: 423px;
                overflow-y: auto;
                display: none;
                margin-bottom: 4px;

                li {
                    transition: all 2s ease-out .5s;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 2.8;
                    color: rgba(0, 0, 0, 0.7);
                    padding: 0 9px;
                    display: inline-block;
                    width: 100%;

                    a {
                        color: rgba(0, 0, 0, 0.7);
                        display: block;
                        width: 100%;
                    }

                    &:hover, &.active {
                        background-color: #e7f5fc;
                    }
                }
            }

            &.open {
                .valueholder {
                    background-color: #f8f8f8;
                    border-color: rgba(0, 0, 0, 0.07);
                    color: #0082c8;

                    &:before {
                        background-image: url('https://www.palassets.com/web/responsive/images/language-icon-hover.svg');
                    }

                    &:after {
                        background-position: 0 -44px;
                    }
                }

                ul {
                    display: block;
                }
            }
        }
    }

    @media all and(min-width: 768px) {
        footer {
            min-height: 369px;
        }
    }

    @media all and (max-width: 740px) {
        footer {
            padding: 32px 4px 56px;
            color: #e6e4e4;

            .nolink, .link {
                margin-bottom: 12px;
                font-weight: 400;
            }

            a {
                color: #e6e4e4;
                font-size: 16px;
                line-height: 28px;
            }

            .copyright {
                font-size: 11px;
                margin: 32px 0 0 16px;
                float: left;
            }

            .content .menu li {
                margin: 0;

                &.expanded {
                    width: 100%;
                    margin: 48px 16px 0 0;
                    padding: 0 16px;
                    float: left;
                }
            }

            .menu li {
                list-style: none;
            }
        }
    }

    @media all and (max-width: 540px) {
        footer {
            #block-locale-language {
                width: 100%;
                max-width: inherit;

                .content {
                    max-width: inherit;

                    .language-dropdown {
                        .valueholder {
                            width: 100%;
                        }
                    }
                }

                ul {
                    width: 100%;
                    max-width: inherit;
                    max-height: 200px;
                }
            }

            .content .menu li {
                margin: 0;

                &.expanded {
                    width: 100%;
                    margin: 48px 16px 0 0;
                    padding: 0 16px;
                    float: left;
                }
            }

            .menu li {
                list-style: none;
            }
        }
    }
</style>

<style lang="scss">
#onetrust-pc-sdk {
  .ot-pc-logo {
    background-image: url('https://www.palassets.com/web/responsive/images/paltalk_logo.svg') !important;
  }
}
#ot-sdk-btn {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.75) !important;
  outline: none;
  &:hover {
    background: none !important;
    color: #fff !important;
  }
}
</style>
