<template>
    <div class="ad-container">
        <div class="ad-728x90">
            <p>advertisement</p>
          <div class="vlmbrm" id="top728" data-aa-pos="top"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'top-ad'
    }
</script>

<style lang="scss" scoped>
@media all and (min-width: 768px) {
    .ad-container {
        margin: 0;
        padding: 0;
        text-align: center;
        .ad-728x90 {
            display: inline-flex;
            justify-content: center;
            margin: -60px auto 0 auto;
            width: auto;
            height: auto;
            min-width: 728px;
            min-height: 105px;
            max-width: 100%;
            float: none;
            padding: 15px 0 0 0;
            position: relative;
            p {
                position: absolute;
                font-size: 10px;
                line-height: 13px;
                text-align: right;
                color: #006a99;
                text-transform: uppercase;
                font-weight: 300;
                right: 13px;
                top: 0;
            }
        }
    }
}

@media all and (max-width: 767px) {
    .ad-container {
        .ad-728x90 {
            margin: 30px auto 0;
            text-align: center;
            > p {
                display: none;
            }
        }
    }
}
</style>
